import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Link from "../../components/link";
import WhatWeDoHero from "../../components/what-we-do-hero";
import HowCanWeHelpRight from "../../components/how-can-we-help-right";
import HowCanWeHelpLeft from "../../components/how-can-we-help-left";
import PhotoLeft from "../../components/photo-left";
import CtaBanner from "../../components/cta-banner";
import BeeBanner from "../../components/bee-banner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { GoPrimitiveDot } from "react-icons/go";
import SVGPatternedSidebar from "../../components/SVG/patterned-sidebar";
import SVGLcdBgPoly1 from "../../components/SVG/lcd-bg-poly-1";
import SVGLcdBgPoly2 from "../../components/SVG/lcd-bg-poly-2";
import SVGLcdBgPoly3 from "../../components/SVG/lcd-bg-poly-3";
import SVGLcdBgPoly4 from "../../components/SVG/lcd-bg-poly-4";
import SVGFBLcd from "../../components/SVG/flying-bee-lcd";

const LeadershipCultureAndDevelopment = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "LCD-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			coreValuesImg: wpMediaItem(title: { eq: "Core-Values-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			trainingImg: wpMediaItem(title: { eq: "Training-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			EEImg: wpMediaItem(title: { eq: "Employee-Engagement-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			employeeAppraisalsImg: wpMediaItem(
				title: { eq: "Employee-Appraisals-Image" }
			) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const trainingImg =
		data.trainingImg?.localFile.childImageSharp.gatsbyImageData;
	const EEImg = data.EEImg?.localFile.childImageSharp.gatsbyImageData;
	const coreValuesImg =
		data.coreValuesImg?.localFile.childImageSharp.gatsbyImageData;
	const employeeAppraisalsImg =
		data.employeeAppraisalsImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Leadership Culture And Development",
				item: {
					url: `${siteUrl}/what-we-do/leadership-culture-and-development`,
					id: `${siteUrl}/what-we-do/leadership-culture-and-development`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Leadership Culture and People Development | Hive HR Solutions"
					description="Hive HR Solutions can help you define your values so that they truly reflect your company's objectives and ethos."
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/what-we-do/leadership-culture-and-development`,
						title:
							"Leadership Culture and People Development | Hive HR Solutions",
						description:
							"Hive HR Solutions can help you define your values so that they truly reflect your company's objectives and ethos.",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className=" pt-md-4 pt-lg-0">
					<WhatWeDoHero
						pos="5rem"
						title="Leadership, Culture and People Development"
						img={
							<div>
								<Row className="g-0 d-lg-block d-none ">
									<Col
										style={{ overflow: "hidden" }}
										className="g-0  pb-0   position-relative"
									>
										<GatsbyImage
											style={{ width: "84%" }}
											className=" tImage  mb-4"
											alt={data.heroImg?.altText}
											image={heroImg}
										/>
										<SVGPatternedSidebar
											style={{ width: "20%", right: "-1rem" }}
											className="position-absolute bg-primary tImage top-0  "
										/>
									</Col>
								</Row>
								<GatsbyImage
									className="w-100 d-lg-none mb-4"
									alt={data.heroImg?.altText}
									image={heroImg}
								/>
							</div>
						}
					/>
				</section>
				<section className="pb-lg-8 pt-xl-7 position-relative">
					<SVGLcdBgPoly1
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--10 d-none d-lg-block "
					/>
					<HowCanWeHelpRight
						height="33rem"
						width="30rem"
						img={coreValuesImg}
						imgAlt={data.coreValuesImg?.altText}
						heading="Your core values are the cornerstone of your leadership approach"
						text={
							<span>
								<p>
									At Hive HR Solutions, we passionately believe that having
									values is essential to running a thriving company where
									management and employees all work in harmony together to
									achieve your company's goals. Your values set the tone for
									everyone in your company. They help create a culture within
									your company that is consistent in its approach to customer
									relations and work practices.
								</p>
								<p>
									Hive HR Solutions can help you define your values so that they
									truly reflect your company's objectives and ethos. These
									values will in turn give your company an organisational
									purpose. They will also represent a set of guidelines for
									current and future employees on how to behave and work.
								</p>
							</span>
						}
						list={
							<ul className="help-list ps-0 ">
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>
									<p className="text-start">
										Running workshops to brainstorm and identify values
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>
									<p className="text-start">
										Developing communications to embed your values across your
										whole business
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>
									<p className="text-start">
										Helping you ensure they are visible and are at the core of
										your business
									</p>
								</li>
							</ul>
						}
					/>
				</section>
				<section className=" position-relative pt-xl-10 pb-lg-6">
					<SVGLcdBgPoly2
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--30  d-none d-lg-block"
					/>
					<SVGFBLcd
						style={{ zIndex: -2, top: "5%", left: "38%" }}
						className="position-absolute  translate-middle-x  d-none d-xl-block "
					/>
					<PhotoLeft
						idLeft="Management-Left"
						idRight="Management-Right"
						height="28rem"
						width="28rem"
						img={trainingImg}
						imgAlt={data.trainingImg?.altText}
						heading="Management Training and Development"
						text={
							<span>
								<p>
									Hive HR Solutions is committed to helping you develop your
									managers so that they can further their career, achieve
									professional satisfaction and add value to your company with
									their newly-acquired skills. If employee retention is
									important to you, one of the best ways to achieve this is
									through professional development and training.
								</p>
								<p>
									We can help you train and develop your managers by providing
									tailored courses to bring the best out of them.
								</p>

								<p>Key aspects managers can receive training on can include:</p>
								<ul className="help-list mt-4 ps-0">
									<li className="d-flex align-items-start">
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
										<p> Giving difficult feedback</p>
									</li>
									<li className="d-flex align-items-start">
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
										<p> Effective communication</p>
									</li>
									<li className="d-flex align-items-start">
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
										<p> Delegation</p>
									</li>
									<li className="d-flex align-items-start">
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
										<p> Motivating team members</p>
									</li>
									<li className="d-flex align-items-start">
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
										<p> Unconscious bias</p>
									</li>
									<li className="d-flex align-items-start">
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
										<p> Emotional intelligence </p>
									</li>
									<li className="d-flex align-items-start">
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
										<p> Performance management</p>
									</li>
								</ul>
							</span>
						}
					/>
				</section>
				<section>
					<CtaBanner
						headline="Ready to speak to us?"
						btnTxt="Get in touch"
						btnLink="/contact-us"
						gradient={true}
					/>
				</section>
				<section className=" pt-lg-6 pt-4 pb-lg-10">
					<HowCanWeHelpRight
						height="25rem"
						width="33rem"
						img={employeeAppraisalsImg}
						imgAlt={data.employeeAppraisalsImg?.altText}
						heading="Employee appraisals"
						text={
							<span>
								<p>
									Everyone wants to feel appreciated for the work they do and we
									want to help you get the best out of your employees by having
									clearly defined goals and targets that are aligned with your
									business goals. This is best delivered by an appraisal.
								</p>
							</span>
						}
						list={
							<ul className="help-list ps-0 ">
								<li className="d-flex align-items-start">
									<div style={{ width: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>
									<p className="text-start">
										Building a robust tailored appraisal process
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2  fs-4" />
									</div>

									<p className="text-start">
										Producing appraisal and 121 documentation that is simple and
										straightforward but also effective
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ width: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>

									<p className="text-start">
										Supporting the appraisal roll-out
									</p>
								</li>

								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>

									<p className="text-start">
										Training managers on holding appraisal meetings
									</p>
								</li>
							</ul>
						}
					/>
				</section>
				<section className="pt-xl-10 pb-lg-10 position-relative">
					<SVGLcdBgPoly3
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--50 d-none d-lg-block "
					/>
					<SVGLcdBgPoly4
						style={{ zIndex: -2 }}
						className="position-absolute start-0 top--30 d-none d-lg-block "
					/>
					<HowCanWeHelpLeft
						height="25rem"
						width="33rem"
						img={EEImg}
						imgAlt={data.EEImg?.altText}
						heading="Employee engagement"
						text={
							<span>
								<p>
									To boost your teams engagement and motivate employees to
									succeed, giving your employees a voice through an engagement
									survey will provide you with a better understanding of their
									perspective and enable you to find out their views and
									opinions on your company and their role.
								</p>
							</span>
						}
						list={
							<ul className="help-list ps-0 ps-lg-2">
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
									</div>
									<p className="text-start">
										Conducting a survey to find out how your employees really
										feel, including company-specific questions
									</p>
								</li>

								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot className="text-primary mt-1 me-2 fs-4" />
									</div>
									<p className="text-start">
										Supporting action planning to address feedback
									</p>
								</li>
								<li className="d-flex align-items-start">
									<div style={{ minWidth: "33px" }}>
										<GoPrimitiveDot
											style={{ minWidth: "21px" }}
											className="text-primary mt-1 me-2 fs-4"
										/>
									</div>
									<p className="text-start">
										Using a survey platform like Wethrive to deliver everything
										you need to empower your managers to react to feedback using
										their action plans, curated learning content and e-learning.
									</p>
								</li>
							</ul>
						}
					/>
				</section>
				<section className="pt-lg-10 mt-lg-7">
					<BeeBanner
						headline="Call now for advice on employee engagement"
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default LeadershipCultureAndDevelopment;
